function forceLoadAppJs() {
    const script = document.createElement('script');
    script.src = `https://witty-river-0ddfd1703.5.azurestaticapps.net/app.js?v=${Date.now()}`;
    script.type = 'text/javascript';
    document.body.appendChild(script);
}
forceLoadAppJs()
// window.addEventListener('load', forceLoadAppJs);

// export { forceLoadAppJs };     
